import React, {Component} from 'react';
import './MintModal.css';
import {
    Button,
    CloseButton,
    Modal
} from 'react-bootstrap';
import {
    GAS_LIMIT_MINIMUM,
    GAS_LIMIT_MAXIMUM,
    GAS_PRICE_SUGGESTION
} from '../../../Tokens';
import {Navigate as Redirect} from 'react-router-dom';
import Link from '../../Helpers/Link';
import Tilt from 'react-parallax-tilt';
import {CloudDownload, Telegram, Twitter, Wallet2} from 'react-bootstrap-icons';

class MintModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stage: this.isConnected() ? 'pricing' : 'connect',
            show: false,
            typeId: null,
            transactionHash: '',
            contract: window.contract || null,
            web3: window.w3 || null,
            accounts: window.w3accounts || [],
            redirect: null,
        };
        this._isMounted = false;
        this.mint = this.mint.bind(this);
        clearInterval(window.modalReload);
    }

    componentDidMount() {
        this._isMounted = true;
        window.modalReload = setInterval(() => {
            if (
                window.mint !== this.state.show
                && this._isMounted
                && this.isConnected()
            ) {
                let stage = this.state.stage;
                if (stage === 'connect') {
                    stage = 'pricing';
                }
                this.setState({
                    stage,
                    show: window.mint,
                    web3: window.w3,
                    contract: window.contract,
                    accounts: window.w3accounts,
                }, this.getPrices);
            }
        }, 250);
    }

    isConnected() {
        return window.w3
            && window.contract
            && window.w3accounts;
    }

    /**
     * Performs checks and balances before making the mintActual call
     *
     * @param typeId
     * @param cost
     * @returns {Promise<void>}
     */
    async mint(typeId, cost) {
        const {contract, web3} = this.state;
        clearInterval(window.waitingForConfirmation);

        // Cannot proceed if contract is paused.
        // if (await contract.methods.paused().call()) {
        //     return this.setState({
        //         stage: 'error',
        //         error: 'NFT minting is currently paused, please come back later.'
        //     });
        // }
        // console.log('Contract is not paused');

        // Cannot proceed if the passType has run out or is not valid.
        const canMint = await contract.methods.canMint(typeId).call();
        if (!canMint) {
            return this.setState({
                stage: 'error',
                error: 'This NFT option just ran out!'
            });
        }
        console.log('canMint returned true');

        // Get the current block gas price
        const costBN = web3.utils.toBN(cost);
        const blockGasPrice = await web3.eth.getGasPrice();
        const blockGasPriceBN = web3.utils.toBN(blockGasPrice);
        const gasPriceSuggestionBN = blockGasPriceBN.div(web3.utils.toBN(100)).mul(web3.utils.toBN(GAS_PRICE_SUGGESTION));
        console.log('Current median gas price (Gwei)', parseInt(web3.utils.fromWei(blockGasPriceBN, 'gwei')));
        console.log('Suggesting gas price (Gwei)', parseInt(web3.utils.fromWei(gasPriceSuggestionBN, 'gwei')));

        // Cannot proceed if we do not have enough ether to cover the cost.
        const balance = await web3.eth.getBalance(window.w3accounts[0]);
        const balanceBN = web3.utils.toBN(balance);
        const minBN = gasPriceSuggestionBN.mul(web3.utils.toBN(GAS_LIMIT_MINIMUM)).add(costBN);
        const maxBN = gasPriceSuggestionBN.mul(web3.utils.toBN(GAS_LIMIT_MAXIMUM)).add(costBN);
        const maxEth = Number(web3.utils.fromWei(maxBN)).toFixed(4).toString();
        console.log('Max Ether needed', maxEth);
        const balanceEth = Number(web3.utils.fromWei(balance)).toFixed(4).toString();
        if (balanceBN.lte(minBN)) {
            return this.setState({
                stage: 'error',
                error: 'You have ' + balanceEth + ' ETH in your connected wallet, but you will need about ' + maxEth + ' ETH to mint this.'
            });
        }
        console.log('Wallet has sufficient ETH', balanceEth);

        // Estimate gas limit for the upcoming transaction.
        let gasLimit = GAS_LIMIT_MINIMUM;
        let gasPrice = gasPriceSuggestionBN;
        console.log('Estimating gasLimit...');
        try {
            const method = typeId === 0 ? 'mintOneWithDiscount' : 'mintMultipleWithDiscount';
            await contract.methods[method]().estimateGas({
                from: window.w3accounts[0],
                value: costBN,
                // maxFeePerGas: gasPrice,
                // maxPriorityFeePerGas: 1
            }, function (error, estimate) {
                if (error) {
                    if (typeof error.code !== 'undefined' && error.code === -32000) {
                        console.error('Suggesting gas price (Gwei) was rejected. Will allow the network to determine price.', error);
                        gasPrice = undefined;
                    } else {
                        console.error('Unexpected error while estimating', error);
                    }
                } else {
                    console.log('Gas Estimate', estimate);
                    // Go slightly above the estimate
                    gasLimit = parseInt(estimate * 1.5);
                    // Limit by our minimum and maximum range
                    gasLimit = Math.max(gasLimit, GAS_LIMIT_MINIMUM);
                    gasLimit = Math.min(gasLimit, GAS_LIMIT_MAXIMUM);
                }
            });
        } catch (e) {
            console.error('Unexpected exception while estimating, cancelling gasPrice suggestion', e);
            gasPrice = undefined;
        }
        console.log('Suggesting gas limit', gasLimit);

        // Cannot proceed if the gasLimit estimate is maxed out,
        // suggesting some manner of error or exploit.
        if (gasLimit === GAS_LIMIT_MAXIMUM) {
            return this.setState({
                stage: 'error',
                error: 'There is an unexpected blockchain issue. Please let us know and try again later!'
            });
        }

        // Begin buy/mint process.
        this.setState({typeId, stage: 'minting'}, async () => {
            const t = this;
            let transactionHash, confirmations = 0;

            console.log('Beginning mint attempt');
            const method = typeId === 0 ? 'mintOneWithDiscount' : 'mintMultipleWithDiscount';
            await contract.methods[method]().send({
                from: window.w3accounts[0],
                value: costBN,
                gas: gasLimit,
                // gasPrice: gasPrice,
                // London fork changes:
                maxPriorityFeePerGas: window.w3.utils.toHex('1500000000'), // 1.5gwei
                maxFeePerGas: gasPrice
            }).on('error', function (e, rec) {
                if (typeof e.code !== 'undefined' && e.code === 4001) {
                    // User aborted the mint, go back to let them
                    // reselect.
                    return t.setState({
                        stage: 'mint'
                    });
                } else {
                    console.error(e, rec);
                    return t.setState({
                        stage: 'error',
                        error: 'Minting failed, please try again.',
                        transactionHash,
                    });
                }
            }).on('transactionHash', function (hash) {
                transactionHash = hash;
                console.info('TransactionHash', transactionHash);

                t.setState({
                    transactionHash,
                    stage: 'confirming'
                });
                console.log('Checking for confirmation');
                let failures = 0;
                window.waitingForConfirmation = setInterval(async () => {
                    let receipt = await web3.eth.getTransactionReceipt(transactionHash);
                    if (!receipt || typeof receipt.status === 'undefined') {
                        // Nothing confirmed yet.
                        return;
                    }
                    if (receipt.status === true) {
                        console.log('Success Receipt', receipt);
                        clearInterval(window.waitingForConfirmation);
                        console.log('Minting successful!', receipt);

                        // Flush this to re-fetch latest tokens.
                        window.tokens = [];
                        window.sessionStorage.setItem('tokens', []);

                        return t.setState({
                            stage: 'minted',
                            typeId,
                            transactionHash,
                            confirmations,
                            receipt,
                        });
                    } else {
                        console.error('Failure Receipt', receipt);
                        failures++;
                        if (failures > 3600) {
                            return t.setState({
                                stage: 'error',
                                error: 'Confirmation was taking too long and may have failed. Please take a look at your confirmation ' + transactionHash + ' on etherscan before trying again.',
                                transactionHash,
                            });
                        }
                    }
                }, 1000);
            });
        });
    }

    redirectTo = (redirect) => {
        if (window.location.pathname !== redirect) {
            this.setState(
                {redirect: redirect},
                () => this.setState({redirect: false})
            );
        }
    };

    async getPrices() {
        const {
            web3,
            contract
        } = this.state;
        this.setState({
            priceSingleBN: web3.utils.toBN(await contract.methods.priceSingle().call()),
            priceMultipleBN: web3.utils.toBN(await contract.methods.priceMultiple().call()),
            stage: 'mint'
        });
    }

    render() {
        const {
            stage,
            show,
            web3,
            error,
            typeId,
            redirect,
            transactionHash,
            priceSingleBN,
            priceMultipleBN
        } = this.state;
        if (redirect && window.location.pathname !== redirect) {
            return <Redirect to={redirect}/>;
        }

        let title = '';
        let body = '';
        let actions = [];

        switch (stage) {
            default:
            case 'connect':
                title = 'PLEASE CONNECT YOUR WALLET';
                break;

            case 'pricing':
                title = 'FINDING BEST PRICES';
                body = (
                    <Modal.Body>
                        <p className="ellipses text-center w-100">
                            Just checking for the best bunny prices right now
                        </p>
                        <h1 className="text-center w-100 anim hovering">
                            <CloudDownload className="text-lg-center"/>
                        </h1>
                    </Modal.Body>
                );
                break;

            case 'mint':

                // let name = typeof TOKEN_NAMES[i] !== 'undefined' ? TOKEN_NAMES[i] : '???';
                // let color = typeof TOKEN_COLORS[i] !== 'undefined' ? TOKEN_COLORS[i] : 'rgba(255,255,255,0.8)';
                // const token = DemoToken(0);
                title = 'ONE BUNNY BABY OR A BUNDLE?';
                body = (
                    <h5 className="text-center w-100 mt-2 strong">
                        Early bunny prices available for a limited time only:
                    </h5>
                );
                actions.push(
                    <div className="m-auto mb-4 mt-4" key="0">
                        <Tilt glareEnable={true}
                              glareMaxOpacity={0.8}
                              glareColor="#ffffff"
                              glarePosition="bottom"
                              glareBorderRadius="0px">
                            <img
                                src="https://bunnies.crocpot.io/factory/0.svg"
                                className="nft"
                                style={{'width': '300px'}}
                                alt={'A bunny baby ready to be minted'}/>
                        </Tilt>
                        <div className="mt-3">
                            <div>
                                Mint a Bunny Baby<br/>
                                (generated when you mint)
                            </div>
                            <div>
                                {'ETH ' + web3.utils.fromWei(priceSingleBN)}
                            </div>
                        </div>
                        <Button
                            className="text-light btn-sm mt-3 mb-4 bunny-btn"
                            key="0"
                            onClick={async () => {
                                this.mint(0, priceSingleBN);
                            }}>
                            MINT A BUNNY BABY!
                        </Button>
                    </div>
                );
                actions.push(
                    <div className="m-auto mb-4 mt-4" key="1">
                        <Tilt glareEnable={true}
                              glareMaxOpacity={0.8}
                              glareColor="#ffffff"
                              glarePosition="bottom"
                              glareBorderRadius="0px">
                            <img
                                src="https://bunnies.crocpot.io/factory/1.svg"
                                className="nft"
                                style={{'width': '300px'}}
                                alt={'A bunny baby ready to be minted'}/>
                        </Tilt>
                        <div className="mt-3">
                            <div>
                                Mint 4+ Bunny Babies<br/>
                                (up to 7 are randomly minted)
                            </div>
                            <div>
                                {'ETH ' + web3.utils.fromWei(priceMultipleBN)}
                            </div>
                        </div>
                        <Button
                            className="text-light btn-sm mt-3 mb-4 bunny-btn"
                            key="1"
                            onClick={async () => {
                                this.mint(1, priceMultipleBN);
                            }}>
                            MINT A BUNDLE OF THEM!
                        </Button>
                    </div>
                );

                break;

            case 'minting':
                title = 'READY TO MINT';
                body = (
                    <Modal.Body>
                        <p className="ellipses text-center w-100">
                            Ready for you to confirm with your wallet
                        </p>
                        <h1 className="text-center w-100 anim hovering">
                            <Wallet2 className="text-lg-center"/>
                        </h1>
                    </Modal.Body>
                );
                break;

            case 'confirming':
                title = 'MINTING IN PROGRESS';
                body = (
                    <Modal.Body>
                        <p className="text-center w-100">
                            Be patient, it takes a little bit of time to see your transaction completed (up to an
                            hour sometimes!).<br/>
                            You can close this window, as the transaction will keep processing.<br/>
                            When ready, your {typeId === 0 ? 'bunny' : 'bunnies'} will be available in your Wallet.
                        </p>
                        <div className="steve-meditate m-auto anim hovering4"/>
                    </Modal.Body>
                );
                actions.push(
                    <div className="m-auto" key={actions.length}>
                        <Link target="_blank" to={'https://etherscan.io/tx/' + transactionHash}
                              className="btn btn-primary btn-sm">
                            View Transaction in Progress
                        </Link>
                    </div>
                );
                break;

            case 'minted':
                title = 'CONGRATULATIONS!';
                body = (
                    <Modal.Body>
                        <p className="text-center">
                            Your {typeId === 0 ? 'bunny is' : 'bunnies are'} being confirmed by the blockchain
                            right now!<br/><br/>
                            We did our best to keep the gas price to a hare minimum,<br/>
                            so it may take a while before things show up in your wallet, Opensea, etc.<br/>
                        </p>
                    </Modal.Body>
                );
                actions.push(
                    <div className="m-auto" key={actions.length}>
                        <Link target="_blank" to={'https://etherscan.io/tx/' + transactionHash}
                              className="btn btn-primary btn-sm">
                            View Transaction
                        </Link>
                    </div>
                );
                // actions.push(
                //     <div className="m-auto" key={actions.length}>
                //         <Link target="_blank"
                //               to={'https://etherscan.io/nft/' + contract.address + '/' + transactionHash}
                //               className="btn btn-primary btn-sm"
                //               onClick={() => {
                //                   window.mint = false;
                //               }}
                //         >
                //             View on Etherscan
                //         </Link>
                //     </div>
                // );
                // actions.push(
                //     <div className="m-auto" key={actions.length}>
                //         <Link target="_blank"
                //               to={'https://etherscan.io/tx/' + transactionHash}
                //               className="btn btn-primary btn-sm"
                //               onClick={() => {
                //                   window.mint = false;
                //               }}
                //         >
                //             View on Opensea
                //         </Link>
                //     </div>
                // );
                actions.push(
                    <div className="m-auto" key={actions.length}>
                        <Link
                            target="_blank"
                            to="https://t.me/BunnyBabiesETH"
                            className="btn btn-primary btn-sm">
                            <Telegram/> Follow Telegram
                        </Link>
                    </div>
                );
                actions.push(
                    <div className="m-auto" key={actions.length}>
                        <Link
                            target="_blank"
                            to="https://twitter.com/BunnyBabiesETH"
                            className="btn btn-primary btn-sm">
                            <Twitter/> Follow Twitter
                        </Link>
                    </div>
                );
                break;

            case 'error':
                title = 'ERROR';
                body = (
                    <Modal.Body>
                        {error}
                    </Modal.Body>
                );
                break;
        }

        return (
            <Modal
                size="lg"
                animation={false}
                show={show}
                aria-labelledby="contained-modal-title-login"
                centered
                onHide={() => {
                }}
                className={'modal-stage-' + stage}
            >
                <CloseButton aria-label="Hide"
                             onClick={() => {
                                 window.mint = false;
                                 this.setState({
                                     'show': false,
                                     'stage': 'mint'
                                 });
                             }}>
                    <div className="cp-cancel float-right">&nbsp;</div>
                </CloseButton>
                <Modal.Header>
                    <h2 className="m-auto">{title}</h2>
                </Modal.Header>
                {body}
                <Modal.Footer className="text-center">
                    {actions}
                </Modal.Footer>
            </Modal>
        );
    }

}

export default MintModal;