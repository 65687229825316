import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import carrotPrize from "../../../images/SVGs/BB-CarrotPrize.svg";
import BunniesCards from "../BunniesCards/BunniesCards";
import "./Chapter.css";

const Chapter = () => {
  return (
    <div className="chapter-and-cards">
      <BunniesCards />
      <div className="chapter-section">
        <Container className="pt-5 chapter-container">
          <h3 className="chapter-title mb-5" id="chapters">
            Bunnies left <br /> to mint...
          </h3>
          {/* chapter1 */}
          <Row xs={1} lg={2} className="mb-5">
            <Col>
              <h3 className="chapter-count">1000</h3>
            </Col>
            <Col>
              <div>
                <p className="chapter-number">CHAPTER 01</p>
                <h3 className="chapter-name">
                  The Hip Hop Club
                </h3>
                <p>
                  The Bunny Babies’ first stop  on their way to Bunny Man is the hottest club in town, where it’s all poppin’ bottles (of carrot juice), getting crewed up, and commencing the hunt for the magic carrot. 
                </p>
              </div>
            </Col>
          </Row>

          {/* chapter2 */}
          <Row xs={1} lg={2} className="mb-5">
            <Col>
              <h3 className="chapter-count">5000</h3>
            </Col>
            <Col>
              <div>
                <p className="chapter-number">CHAPTER 02</p>
                <h3 className="chapter-name">
                  The Millionhare
                </h3>
                <p>
                Out of 1,000,000 hares, the Bunny Babies must find The One that holds the clue to the location of the  magic carrot. Listening with all their ears, they find the furry master of disguise. This mysterious bunny holds the clue. 
                </p>
              </div>
            </Col>
          </Row>

          {/* chapter3 */}
          <Row xs={1} lg={2} className="mb-5">
            <Col>
              <h3 className="chapter-count">7000</h3>
            </Col>
            <Col>
              <div>
                <p className="chapter-number">CHAPTER 03</p>
                <h3 className="chapter-name">
                  24 Carrots
                </h3>
                <p>
                Gold! The clue unveils the secret garden location of the magic carrot...underground.  With key in foot, the Bunny Babies continue on their journey.
                </p>
              </div>
            </Col>
          </Row>

          {/* chapter4 */}
          <Row xs={1} lg={2} className="mb-5">
            <Col>
              <h3 className="chapter-count">10,000</h3>
            </Col>
            <Col>
              <div>
                <p className="chapter-number">CHAPTER 04</p>
                <h3 className="chapter-name">
                  Bunny Man
                </h3>
                <p>
                At last, the Bunny Babies arrive at the desert paradise complete with revolutionary pyrotechnic-rigged irrigation systems, bringing life to endless gardens of nourishment, for all to share.  Bunny Babies have reached their utopia and are well on their way to enlightenment.
                </p>
              </div>
            </Col>
          </Row>
          {/*  */}
          <Row xs={1} lg={2} className="align-items-center chapter-bottom">
            <Col>
              <img className="carrot-prize" src={carrotPrize} alt="" />
            </Col>

            <Col>
              <button className="chapter-btn" onClick={() => {window.mint = true}}>JOIN THE FIRST CHAPTER!</button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Chapter;
