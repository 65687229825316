import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "./Animations.css";
import Home from "./components/Home/Home/Home";
// import Navigation from "./components/Navigation/Navigation";
import OneSignal from 'react-onesignal';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

// Do not run logrocket in local development.
if (window.location.href.indexOf(':3000') === -1) {
    setupLogRocketReact(LogRocket);
    LogRocket.init('crocpot/nft-oupic');
    OneSignal.init({
        appId: "1c6536d5-4376-43f1-9895-57164fb7dcef"
    });
}

function App() {
  return (
    <div className="App">
      <Router>
        {/* routes  */}
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
