import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./MagicCarrot.css";
import magicCarrot from "../../../images/SVGs/BB-MagicCarrot.svg";
import Wallet from "../Mint/Wallet";

const MagicCarrot = () => {
  return (
    <div className="magic-carrot-section p-5" id="magic-carrot">
      <Container className="py-5">
        <Row className="align-items-center magic-carrot-row">
          <Col xs={12} lg={6} className="text-center">
            <img className="magic-carrot" src={magicCarrot} alt="" />
          </Col>
          <Col xs={12} lg={6}>
            <div>
              <h3 className="magic-carrot-title mb-3">
                Once upon a time <br /> there was a magic carrot..
              </h3>
              <p>
              The carrot is the key to a realm only known to those bunnies willing to take the risk, willing to go the distance, hop the long road to the ultimate desert cottontail festival, Bunny Man. 
              <br /> <br />{" "}
                <b>
                Secure your Bunny Babies on their journey to Bunny Man!
                </b>
              </p>
              {/*<button className="magic-carrot-btn mt-5">*/}
              {/*  MINT YOUR BUNNY BABIES*/}
              {/*</button>*/}
              <Wallet/>
            </div>
          </Col>
        </Row>
      </Container>
      <Wallet nfts={true}/>
    </div>
  );
};

export default MagicCarrot;
