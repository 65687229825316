import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
// import { Link } from "react-router-dom";
import "./Navigation.css";
import Link from "../Helpers/Link";

const Navigation = () => {
  return (
    <div>
      <Navbar className="nav-bar" variant="dark" expand="lg">
        <Container className="">
          <Navbar.Brand></Navbar.Brand>
          <Navbar.Toggle
            className="mobile-menu"
            aria-controls="basic-navbar-nav"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto gap-4">
              <Nav.Link className="nav-link" as={Link} to="/#chapters">
                THE STORY
              </Nav.Link>
              <Nav.Link className="nav-link" as={Link} to="/#bunnies">
                THE BUNNIES
              </Nav.Link>
              <Nav.Link className="nav-link" as={Link} to="/#chapters">
                THE CHAPTERS
              </Nav.Link>
              <Nav.Link className="nav-link" as={Link} to="/#team">
                TEAM
              </Nav.Link>
              <Nav.Link className="nav-link" as={Link} to="/#faq">
                FAQ
              </Nav.Link>
              <Link className="bunny-btn nav-btn btn" to="https://opensea.io/collection/bunnybabies" target="_blank">
                OUT NOW <br /> <span>available on OpenSea</span>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Navigation;
