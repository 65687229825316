/**
 * In simulations this was always sufficient, but we will run a web3
 * estimate, and go 50% above the estimate. In addition we will bound
 * by the following range:
 */
export const GAS_LIMIT_MINIMUM = 90000; // 84,417 minting 1 on mainnet
export const GAS_LIMIT_MAXIMUM = 300000; // 247,073 minting 7 on rinkeby

/**
 * Percent of the median price to attempt to use.
 * 97 = 3% lower than the median
 */
export const GAS_PRICE_SUGGESTION = 125;

export const TOKEN_LIMIT = 10000;

export function TokenSvg(tokenId) {
    return 'https://bunnies.baby/svg/' + tokenId + '.svg';
}

export function DemoTokenSvg() {
    return TokenSvg(parseInt(Math.random() * TOKEN_LIMIT));
}