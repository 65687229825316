import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import "./FAQ.css";
// import Link from "../../Helpers/Link"

const FAQ = () => {
  return (
    <div className="faq-section">
      <Container className="py-5 mb-5">
        <Row className="mb-5">
          <Col xs={12} lg={5}>
            <div style={{ color: "#046FB5" }} id="faq">
              <h3>The most important questions, answered!</h3>
              <p>
                If you have more questions, don’t hesitate to contact us on Twitter or Discord
                or join our community on{" "}
                <span style={{ color: "#eca5cf", fontWeight: 700 }}>
                  Telegram!
                </span>
              </p>
            </div>
          </Col>
          <Col xs={12} lg={7}>
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header className="faq-header">
                  How does it work?
                </Accordion.Header>
                <Accordion.Body>
                  After you click the "MINT YOUR BUNNY BABIES" button you secure your bunny(NFT), starting
                  their journey. All bunnies are unique and depending on their traits/boosts will determine their rarity. 
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header className="faq-header">
                  How much for that Bunny Baby?
                </Accordion.Header>
                <Accordion.Body>
                  0.05 ETH + GAS for 1 Bunny Baby and your chance to find the magical carrot.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header className="faq-header">
                  How Does the Magic Carrot work?
                </Accordion.Header>
                <Accordion.Body>
                  At the end of each Chapter, a select number of bunnies in the batch find the magic carrot, these magic keys 
                  to the realm are worth 3% of the sales of that chapter. In the Final chapter, a very special 1:1 Golden Carrot will be discovered
                  and give its finder 10% of the sales of the final chapter!
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header className="faq-header">
                  Where's my Bunny Baby? I just purchased one but it's not showing up!
                </Accordion.Header>
                <Accordion.Body>
                  Transactions on the blockchain vary in time, so please be patient
                  as it can take a few minutes to process and our site to sync.
                  If your transaction was successful, no need to worry your Bunny Baby will appear
                  to you, the rightful owner.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header className="faq-header">
                  Where do I find out about updates?
                </Accordion.Header>
                <Accordion.Body>
                  You can join our Discord or Telegram Group to keep update on all the project updates.
                  Stay tuned as we continue to grow the project and continue the journey!
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FAQ;
