import React from "react";
import Footer from "../../Footer/Footer";
import Banner from "../Banner/Banner";
import BehindTheScene from "../BehindTheScene/BehindTheScene";
import BunnyCollection from "../BunnyCollection/BunnyCollection";
import Chapter from "../Chapter/Chapter";
import FAQ from "../FAQ/FAQ";
import MagicCarrot from "../MagicCarrot/MagicCarrot";
import MintModal from "../Mint/MintModal";

const Home = () => {
  return (
    <div id="home">
      {/* banner  */}
      <Banner />
      {/* magic carrot  */}
      <MagicCarrot />
      {/* bunny collection  */}
      <BunnyCollection />
      {/* Chapter  */}
      <Chapter />
      {/* BehindThescene  */}
      <BehindTheScene />
      {/* FAQ  */}
      <FAQ />
      {/* footer  */}
      <Footer />
      {/* MintModal  */}
      <MintModal/>
    </div>
  );
};

export default Home;
