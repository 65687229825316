import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import bbBrandMark from "../../images/SVGs/BB-brandmark.svg";
import "./Footer.css";
import openSea from "../../images/SVGs/BB-opensea-icon.svg";
import twitter from "../../images/SVGs/BB-twitter-icon.svg";
import telegram from "../../images/SVGs/BB-telegram-icon.svg";
import { Link } from "react-router-dom";
import bbTube from "../../images/SVGs/BB-Tube.svg";
import ContractNFTJSON from '../../contracts/BunnyBabies.json';

const Footer = () => {
  return (
    <div className="footer">
      <img className="bbTube" src={bbTube} alt="" />
      <Container className="py-5 footer-section">
        <Row className="align-items-center">
          <Col xs={12} lg={2}>
            <img src={bbBrandMark} alt="" />
          </Col>
          <Col xs={12} lg={5}>
            <div className="text-white w-80">
              <p>
                <b>Bunny Babies</b> adheres to the ERC-721 standard so you can
                trade them on platforms like OpenSea and many other platforms.
              </p>
              <div>
                <p style={{ fontSize: 16 }}>
                  Verified smart contract address: <br />
                  <span className="smart-contact">
                    {ContractNFTJSON.networks["1"].address}
                  </span>
                </p>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={5}>
            <div className="text-end">
              <div className="social-icons">
                <a href="https://opensea.io/collection/bunnybabies" target="_blank" rel="noreferrer">
                  <img src={openSea} alt="" />
                </a>
                <a href="https://twitter.com/BunnyBabiesETH" target="_blank" rel="noreferrer">
                  <img src={twitter} alt="" />
                </a>
                <a href="https://t.me/BunnyBabiesETH" target="_blank" rel="noreferrer">
                  <img src={telegram} alt="" />
                </a>
              </div>
              <div className="text-white my-3">
                <p className="copyright">
                  Copyright ©2022. All Rights Reserved.
                </p>
              </div>
              <div className="footer-links">
                <Link className="footer-link" to="/">
                  Terms & Conditions
                </Link>
                <Link className="footer-link" to="/">
                  Privacy Policy
                </Link>
                {/*<Link className="footer-link" to="/">*/}
                {/*  The Labyrinth Game*/}
                {/*</Link>*/}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
