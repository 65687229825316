import React from "react";
import { Container } from "react-bootstrap";
import Navigation from "../../Navigation/Navigation";
import bunnyBabies from "../../../images/SVGs/BB-BunnyLogo.svg";
import "./Banner.css";
import bunnyTop from "../../../images/SVGs/BB-BunnyTop.svg";
import bunnyLeft from "../../../images/SVGs/BB-BunnyLeft.svg";
import bunnyRight from "../../../images/SVGs/BB-BunnyRight.svg";
import bunnyRightBottom from "../../../images/SVGs/BB-BunnyRightBottom.svg";
import bunnyTrio from "../../../images/SVGs/BB-BunnyTrio.svg";

const Banner = () => {
  return (
    <div className="header-section">
      <Navigation />
      <div className="banner">
        <Container className="pt-5 text-center banner-container">
          {/* bunny top  */}
          <div className="bunny-top">
            <img src={bunnyTop} alt="" />
          </div>
          {/* bunny left  */}
          <div className="bunny-left">
            <img src={bunnyLeft} alt="" />
          </div>
          {/* bunny right  */}
          <div className="bunny-right">
            <img src={bunnyRight} alt="" />
          </div>
          <div className="out-now invisible">
            <h3>OUT NOW</h3>
            <span>ON OPENSEA</span>
          </div>
          <div>
            <img className="bunny-babies" src={bunnyBabies} alt="" />
            <h1 className="text-white my-4 banner-title">
              The most adorable Bunnies on the Blockchain!
            </h1>
          </div>
          {/* bunny right bottom  */}
          <div className="bunny-right-bottom">
            <img src={bunnyRightBottom} alt="" />
          </div>
          {/* bunny tro  */}
          <div className="bunny-trio">
            <img src={bunnyTrio} alt="" />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Banner;
