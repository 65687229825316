import React from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import handSign from "../../../images/SVGs/BB-Hand.svg";
import buuny1 from "../../../images/SVGs/bunnies-card/bunny-01.svg";
import buuny2 from "../../../images/SVGs/bunnies-card/bunny-02.svg";
import buuny3 from "../../../images/SVGs/bunnies-card/bunny-03.svg";
import buuny4 from "../../../images/SVGs/bunnies-card/bunny-04.svg";
import buuny5 from "../../../images/SVGs/bunnies-card/bunny-05.svg";
// install Swiper modules
SwiperCore.use([Navigation]);

const cards = [
  { id: "1", img: buuny1 },
  { id: "2", img: buuny2 },
  { id: "3", img: buuny3 },
  { id: "4", img: buuny4 },
  { id: "5", img: buuny5 },
];

const BunniesCards = () => {
  return (
    <div>
      <div className="bunnies-card-section">
        <Swiper
          style={{ paddingBottom: 40 }}
          grabCursor={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          className="mySwiper"
          slidesPerView={1}
          spaceBetween={10}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
          }}
        >
          {cards.map((card) => (
            <SwiperSlide key={card.id}>
              <div className="card">
                <img src={card.img} alt="" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="hand-sign">
        <img src={handSign} alt="" />
        <span className="hand-sign-text">DRAG FOR MORE!</span>
      </div>
    </div>
  );
};

export default BunniesCards;
