import React from "react";
import { Container } from "react-bootstrap";
import "./BunnyCollection.css";
// import Link from "../../Helpers/Link";

const BunnyCollection = () => {
  return (
    <div className="bunny-collection-section" id="bunnies">
      <Container className="py-5">
        <div className="collection-box">
          <h3>The Bunny Babies Collection!</h3>
          <p>
            Pick your Bunny Babies and help them to find the Magic Carrot! <br/>
            This is a small sample of bunnies you may find.
          </p>
          {/* lets bring this link in when more have been purchased? */}
          {/*<Link className="btn bunny-btn" to="https://opensea.io/collection/bunnybabies" target="_blank">*/}
          {/*  DISCOVER THE COLLECTION*/}
          {/*</Link>*/}
        </div>
      </Container>
    </div>
  );
};

export default BunnyCollection;
