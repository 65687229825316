import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./BehindTheScene.css";
import carrot from "../../../images/SVGs/BB-carrots-icon.svg";
import magician from "../../../images/SVGs/BB-hat-icon.svg";
import dreamer from "../../../images/SVGs/BB-sleepers-icon.svg";
import planner from "../../../images/SVGs/BB-watch-icon.svg";

const BehindTheScene = () => {
  return (
    <div className="behind-the-screen p-4">
      <Container className="py-5" id="team">
        <h3 className="text-center behind-title mb-5">Behind the scene</h3>
        <Row xs={1} lg={4} className="g-4">
          <Col>
            <div className="text-center">
              <img src={carrot} alt="" />
              <p className="sub-title">THE MIND</p>
              <h3 className="mb-2">DB Hurley</h3>
              <p>
              Behind the hats and the tricks is DB, the mind behind the show. Quick wit and a quicker hand 
wisely choose the cast and crew to pull the magic together. Big ideas and big execution is what 
he’s known for, built on passion and perseverance.
              </p>
            </div>
          </Col>

          <Col>
            <div className="text-center">
              <img src={magician} alt="" />
              <p className="sub-title">THE MAGICIAN</p>
              <h3 className="mb-2">Heath Dutton</h3>
              <p>
              What is a show without a showman, bringing ideas to the center stage, showing the world a 
performance. If seeing is believing, Heath makes it happen. Belief isn’t everything, though, he 
makes you want to be in the show.
              </p>
            </div>
          </Col>

          <Col>
            <div className="text-center">
              <img src={dreamer} alt="" />
              <p className="sub-title">THE DREAMER</p>
              <h3 className="mb-2">Chiara Aliotta</h3>
              <p>
              She is ponderous and brimming with colossal ideations, but she has dreams and creates reality.
How does an innocent Baby Bunny get to the magic carrot? The road can’t be easy, that would 
be boring. Chiara brings the excitement, the creativity that designs the path.
              </p>
            </div>
          </Col>

          <Col>
            <div className="text-center">
              <img src={planner} alt="" />
              <p className="sub-title">THE PLANNER</p>
              <h3 className="mb-2">Kevin Coelho</h3>
              <p>
              The feat of navigating a labyrinth is perplexing, and you always need a planner on your side. 
Kevin not only brings the maps, he makes them. He guides the team, collects those who stray 
and lights up the path to the future.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BehindTheScene;
