import React, {Component} from 'react';
import getWalletConnect from '../../../getWalletConnect';
import ContractJSON from '../../../contracts/BunnyBabiesFactory.json';
import ContractNFTJSON from '../../../contracts/BunnyBabies.json';
import {
    Col,
    Container,
    Dropdown,
    Row
} from 'react-bootstrap';
import {
    TokenSvg
} from '../../../Tokens';
import {Navigate as Redirect} from 'react-router-dom';
import Tilt from 'react-parallax-tilt';
// import Message from "../../Helpers/Message";
// import Link from "../../Helpers/Link";

class Wallet extends Component {
    constructor(props) {
        super(props);
        const web3 = window.w3 || this.props.web3 || null;
        const accounts = this.storageGet('w3accounts', []);
        let stage = 'ready';
        if (accounts.length) {
            stage = 'connecting';
        }
        this.state = {
            web3: web3,
            networkId: this.storageGet('w3chainid', null),
            nfts: window.nfts || [],
            tokens: this.storageGet('tokens', []),
            passTypes: this.storageGet('passTypes', {}),
            accounts: accounts,
            stage,
            error: null,
            contract: null,
            contractNFT: null,
            requests: []
        };
        this.connect = this.connect.bind(this);
        this.accountsChanged = this.accountsChanged.bind(this);
        this.chainChanged = this.chainChanged.bind(this);
        // this.blockie = this.blockie.bind(this);
        this.loadContract = this.loadContract.bind(this);
        this.offlineMode = this.offlineMode.bind(this);
        this.disconnect = this.disconnect.bind(this);
        this._isMounted = false;
        clearInterval(this.nftReload);
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.nftReload);
    }

    accountsChanged(accounts) {
        console.log('Accounts changed ' + (accounts[0] || ''));
        this.storageSet('w3accounts', accounts);
        if (accounts.length) {
            return this.setState({
                accounts,
                stage: 'connected'
            }, this.loadContract);
        } else {
            return this.setState({
                accounts,
                stage: 'ready'
            });
        }
    }

    // blockie (account) {
    //     // Ported Blockies implementation to match Etherscan.
    //     let randSeed = new Array(4);
    //
    //     function seedRand (seed) {
    //         for (let i = 0; i < randSeed.length; i++) {
    //             randSeed[i] = 0;
    //         }
    //         for (let i = 0; i < seed.length; i++) {
    //             randSeed[i % 4] = ((randSeed[i % 4] << 5) - randSeed[i % 4]) + seed.charCodeAt(i);
    //         }
    //     }
    //
    //     function rand () {
    //         let t = randSeed[0] ^ (randSeed[0] << 11);
    //         randSeed[0] = randSeed[1];
    //         randSeed[1] = randSeed[2];
    //         randSeed[2] = randSeed[3];
    //         randSeed[3] = (randSeed[3] ^ (randSeed[3] >> 19) ^ t ^ (t >> 8));
    //         return (randSeed[3] >>> 0) / ((1 << 31) >>> 0);
    //     }
    //
    //     function createColor () {
    //         let h = Math.floor(rand() * 360);
    //         let s = ((rand() * 60) + 40) + '%';
    //         let l = ((rand() + rand() + rand() + rand()) * 25) + '%';
    //         return 'hsl(' + h + ',' + s + ',' + l + ')';
    //     }
    //
    //     function createImageData (size) {
    //         let width = size;
    //         let height = size;
    //         let dataWidth = Math.ceil(width / 2);
    //         let mirrorWidth = width - dataWidth;
    //         let data = [];
    //         for (let y = 0; y < height; y++) {
    //             let row = [];
    //             for (let x = 0; x < dataWidth; x++) {
    //                 row[x] = Math.floor(rand() * 2.3);
    //             }
    //             let r = row.slice(0, mirrorWidth);
    //             r.reverse();
    //             row = row.concat(r);
    //             for (let i = 0; i < row.length; i++) {data.push(row[i]);}
    //         }
    //         return data;
    //     }
    //
    //     function createCanvas (imageData, color, scale, bgColor, spotColor) {
    //         let c = document.createElement('canvas');
    //         let width = Math.sqrt(imageData.length);
    //         c.width = c.height = width * scale;
    //         let cc = c.getContext('2d');
    //         cc.fillStyle = bgColor;
    //         cc.fillRect(0, 0, c.width, c.height);
    //         cc.fillStyle = color;
    //         for (let i = 0; i < imageData.length; i++) {
    //             let row = Math.floor(i / width);
    //             let col = i % width;
    //             cc.fillStyle = (imageData[i] === 1) ? color : spotColor;
    //             if (imageData[i]) {cc.fillRect(col * scale, row * scale, scale, scale);}
    //         }
    //         return c;
    //     }
    //
    //     function createIcon (opts) {
    //         opts = opts || {};
    //         let size = opts.size || 8;
    //         let scale = opts.scale || 4;
    //         let seed = opts.seed || Math.floor((Math.random() * Math.pow(10, 16))).toString(16);
    //         seedRand(seed);
    //         let color = opts.color || createColor();
    //         let bgColor = opts.bgColor || createColor();
    //         let spotColor = opts.spotColor || createColor();
    //         let imageData = createImageData(size);
    //         return createCanvas(imageData, color, scale, bgColor, spotColor);
    //     }
    //
    //     return createIcon({
    //         seed: account.toLowerCase(),
    //         scale: 5
    //     }).toDataURL();
    // }

    chainChanged(input) {
        const {web3} = this.state;
        const networkId = typeof input == 'string' ? web3.utils.hexToNumber(input) : input;
        console.log('Chain changed', networkId);
        if (!this.getNetwork(networkId)) {
            return this.setState({
                networkId,
                stage: 'error',
                error: 'Please connect your wallet to the Ethereum Mainnet'
            });
        } else {
            return this.setState({
                networkId
            }, this.loadContract);
        }
    }

    async componentDidMount() {
        const {stage} = this.state;
        this._isMounted = true;
        this.nftReload = setInterval(() => {
            let wnft = window.nfts || [];
            if (
                this._isMounted
                && wnft !== this.state.nfts
            ) {
                this.setState({
                    nfts: window.nfts
                });
            }
        }, 500);
        if (stage === 'connecting') {
            await this.connect(false);
        } else {
            await this.offlineMode();
        }
    }

    async connect(mint) {
        this.setState({
            stage: 'connecting'
        }, async () => {
            try {
                // Get network provider and web3 instance.
                const web3 = window.w3 || await getWalletConnect(this.accountsChanged, this.chainChanged, this.disconnect);
                const networkId = await web3.eth.net.getId();
                window.w3 = web3;
                this.setState({
                    web3,
                    networkId
                }, async () => {
                    if (!this.state.networkId.length) {
                        // Handle situations where the initial hit is on the
                        // wrong network.
                        this.chainChanged(networkId);
                    }
                    if (!this.state.accounts || !this.state.accounts.length) {
                        // Do not attempt eth_requestAccounts with WalletConnect
                        if (typeof window.w3.currentProvider.infuraId === 'undefined') {
                            web3.currentProvider.request({
                                method: 'eth_requestAccounts',
                            }).then(async () => {
                                this.accountsChanged(await web3.eth.getAccounts());
                                window.mint = mint;
                            }).catch((err) => {
                                if (err.code === 4001) {
                                    // EIP-1193 userRejectedRequest error
                                    // If this happens, the user rejected the
                                    // connection request.
                                    this.disconnect();
                                } else {
                                    console.error(err);
                                }
                            });
                        } else {
                            this.accountsChanged(await web3.eth.getAccounts());
                        }
                    } else {
                        this.accountsChanged(await web3.eth.getAccounts());
                    }
                });
            } catch (error) {
                console.warn('disconnecting', error);
                this.setState({
                    stage: 'error',
                    error: 'Please connect your MetaMask (or similar) wallet'
                });
            }
        });
    }

    async disconnect(code, reason) {
        console.log('disconnecting', code, reason);
        window.tokens = null;
        window.w3accounts = [];
        window.sessionStorage.clear();
        this.setState({
            nfts: {},
            tokens: [],
            passTypes: {},
            accounts: [],
            stage: 'ready',
        }, this.offlineMode);
    }

    async getNFTs(callback) {
        const {accounts, contractNFT} = this.state;
        let nfts = [];
        // return '';
        if (contractNFT && accounts.length > 0) {
            const balance = await contractNFT.methods.balanceOf(accounts[0]).call();
            console.log('NFT balance', balance);
            window.balance = balance;
            if (balance > 0) {
                let tokenIds = [];
                // Add those transferred to user
                let events = await contractNFT.getPastEvents('Transfer', {
                    filter: {
                        'to': accounts[0]
                    },
                    fromBlock: 0,
                    toBlock: 'latest'
                });
                for (let i = 0; i <= events.length - 1; i++) {
                    // let index = (events[i].blockNumber * 1000) + events[i].logIndex
                    let tokenId = events[i].returnValues.tokenId;
                    tokenIds[tokenId] = {
                        blockNumber: events[i].blockNumber,
                        logIndex: events[i].logIndex
                    };
                }
                if (Object.keys(tokenIds).length > 0) {
                    // Remove tokens transferred away
                    events = await contractNFT.getPastEvents('Transfer', {
                        filter: {
                            'from': accounts[0]
                        },
                        fromBlock: 0,
                        toBlock: 'latest'
                    });
                    for (let i = 0; i <= events.length - 1; i++) {
                        let tokenId = events[i].returnValues.tokenId;
                        if (
                            typeof tokenIds[tokenIds] !== 'undefined'
                            && (
                                tokenIds[tokenIds].blockNumber < events[i].blockNumber
                                || tokenIds[tokenIds].logIndex < events[i].logIndex
                            )
                        ) {
                            // Address has transferred this token away (or sold)
                            delete tokenIds[tokenId];
                        }
                    }
                }
                if (Object.keys(tokenIds).length) {
                    let d = 0;
                    for (let tokenId in tokenIds) {
                        if (!tokenIds.hasOwnProperty(tokenId)) {
                            continue;
                        }

                        // Cheating here for efficiency for users with many NFTs
                        let src = TokenSvg(tokenId);
                        console.log(src);
                        let label = 'Bunny Baby #' + tokenId;
                        let style = {'animationDelay': (d * 200) + 'ms'};
                        nfts.push(
                            <div key={tokenId}
                                 className="nft-container mb-5">
                                <div className="anim"
                                     style={style}>
                                    <div className="flip-in-hor-bottom-2"
                                         style={style}>
                                        <div
                                            className="tilt-in-top-2"
                                            style={style}>
                                            <Tilt glareEnable={true}
                                                  scale={1.1}
                                                  glareMaxOpacity={0.8}
                                                  glareColor="#ffffff"
                                                  glarePosition="bottom"
                                                  glareBorderRadius="1.5em"
                                            >
                                                <img src={src} alt={label} className="nft"/>
                                            </Tilt>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex mt-3">
                                    <div className="w-100">
                                        <span className="nft-under">{label}</span>
                                    </div>
                                    <div className="nft-actions flex-shrink-1">
                                         <Dropdown>
                                             <Dropdown.Toggle variant="">
                                                 &bull;&bull;&bull;
                                             </Dropdown.Toggle>
                                             <Dropdown.Menu>
                                                 <Dropdown.Item
                                                     href={'https://etherscan.io/nft/' + contractNFT.address + '/' + tokenId}
                                                     target="_blank">View on Etherscan</Dropdown.Item>
                                                 <Dropdown.Item
                                                     href={'https://opensea.io/assets/' + contractNFT.address + '/' + tokenId}
                                                     target="_blank">View on OpenSea</Dropdown.Item>
                                                 <Dropdown.Item
                                                     href={src}
                                                     target="_blank">Full Size</Dropdown.Item>
                                             </Dropdown.Menu>
                                         </Dropdown>
                                     </div>
                                </div>
                            </div>
                        );
                        d += .6;
                    }
                }
            }
            if (nfts.length) {
                window.nfts = nfts;
                this.setState({
                    nfts
                });
            }
        }
        return nfts;
    }

    getNetwork(networkId) {
        return ContractJSON.networks[networkId] || false;
    }

    async getPassTypes() {
        const {contract, passTypes, stage} = this.state;
        // Counts may have changed, keep refreshing?
        // if (Object.keys(passTypes).length) {
        //     return passTypes;
        // }
        let newPassTypes = {};
        if (stage === 'connected') {
            for (let i = 0; i <= 255; i++) {
                let type = await contract.methods.passTypes(i).call();
                if (type.destinationA === '0x0000000000000000000000000000000000000000') {
                    // Stop searching for types when we hit an empty one
                    break;
                }
                newPassTypes[i] = {
                    cost: type.cost,
                    count: type.count,
                    maxTotal: type.maxTotal,
                    destinationA: type.destinationA,
                    destinationB: type.destinationB,
                };
            }
        }
        if (newPassTypes !== passTypes) {
            this.storageSet('passTypes', newPassTypes);
            await this.setState({passTypes: newPassTypes});
        }
    }

    loadContract() {
        const {
            networkId,
            accounts,
            web3,
            contract
        } = this.state;
        const network = this.getNetwork(networkId);
        if (
            web3
            && network
            && (typeof accounts !== 'undefined' && accounts && accounts.length > 0)
            && !contract
        ) {
            let contract = new web3.eth.Contract(
                ContractJSON.abi,
                network.address,
            );
            let contractNFT = new web3.eth.Contract(
                ContractNFTJSON.abi,
                ContractNFTJSON.networks[networkId].address
            )
            this.setState({
                contract,
                contractNFT
            }, this.getNFTs);
            window.contract = contract;
        }
    }

    async offlineMode() {
        console.log('offline mode');
    }

    redirectTo = (redirect) => {
        if (window.location.pathname !== redirect) {
            this.setState(
                {redirect: redirect},
                () => this.setState({redirect: false})
            );
        }
    };

    render() {
        const {redirect} = this.state;
        if (redirect && window.location.pathname !== redirect) {
            return <Redirect to={redirect}/>;
        }

        if (this.props.nfts) {
            return this.renderNFTs();
        } else {
            return this.renderLogin();
        }
    }

    renderLogin() {
        const {stage, error, nfts} = this.state;

        switch (stage) {
            case 'ready':
                return (
                    <>
                        <div id="wallet" className={stage}
                             onClick={() => {this.connect(true)}}>
                            <span className="btn magic-carrot-btn mt-5">MINT YOUR BUNNY BABIES</span>
                        </div>
                        <p className="mt-4 text-decoration-underline" onClick={() => {this.connect(false)}}>
                            <small>or connect your wallet to view your bunnies</small>
                        </p>
                    </>
                );
            case 'connecting':
                return (
                    <>
                        <div id="wallet" className="connecting"
                             onClick={this.disconnect}>
                            <span className="ellipses btn magic-carrot-btn mt-5">CONNECTING</span>
                        </div>
                        <div className="mt-5 cp-pot m-auto anim slide-in-blurred-top" role="img"/>
                    </>
                );
            case 'connected':
                if (nfts && Object.keys(nfts).length > 0) {
                    // let part2 = accounts[0].substring(accounts[0].length - 9);
                    // let part1 = accounts[0].substring(0, accounts[0].length - part2.length);
                    return (
                        <>
                            <div id="wallet" className="ready"
                                 onClick={() => {
                                     window.mint = true;
                                 }}>
                                <span className="btn magic-carrot-btn mt-5">MINT MORE BUNNY BABIES</span>
                            </div>
                        </>
                    );
                } else {
                    return (
                        <>
                            <div id="wallet" className="ready"
                                 onClick={() => {
                                     window.mint = true;
                                 }}>
                                <span className="btn magic-carrot-btn mt-5">MINT YOUR BUNNY BABIES</span>
                            </div>
                        </>
                    );
                }
            case 'error':
            default:
                return (
                    <>
                        <div id="wallet" className={stage}>
                            <span className="text-warning">
                                {error}
                            </span>
                        </div>
                    </>
                );
        }
    }

    renderNFTs() {
        const {nfts} = this.state;
        if (!nfts) {
            return '';
        }
        return (
            <div className="container-fluid">
                <Container>
                    <Col className="col-12">
                        <Row className="pt-5 m-auto m-lg-0 text-center text-lg-start">
                            <h2 className="mt-4">YOUR {nfts.length > 1 ? 'BUNNIES' : 'BUNNY'}</h2>
                        </Row>
                        <Row className="pt-3 pb-5 m-auto m-lg-0">
                            {nfts}
                            <span
                                className="nft d-flex align-items-center float-end clickable"
                                onClick={() => {
                                    window.mint = true;
                                }}>
                                <div
                                    className="nft-add-position-plus align-middle m-auto">
                                    <div
                                        className="btn magic-carrot-btn mt-5">
                                        MINT MORE
                                    </div>
                                </div>
                            </span>
                        </Row>
                    </Col>
                </Container>
            </div>
        );
    }

    storageGet = (key, def) => {
        if (typeof window[key] !== 'undefined') {
            return window[key];
        }
        let str = window.sessionStorage.getItem(key) || '';
        if (str.length) {
            try {
                return JSON.parse(str);
            } catch (e) {
                def = str;
                // console.error(e);
            }
        }
        return def;
    };

    storageSet = (key, value) => {
        window[key] = value;
        window.sessionStorage.setItem(key, JSON.stringify(value));
    };
}

export default Wallet;
